import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Service} from '../../../models';

@Component({
    selector: 'prism-service-brief-dialog',
    templateUrl: './service-brief-dialog.component.html',
    styleUrls: ['./service-brief-dialog.component.scss']
})
export class ServiceBriefDialogComponent implements OnInit {
    service: Service;
    fileCount: number;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        public dialogRef: MatDialogRef<ServiceBriefDialogComponent>
    ) {
    }

    ngOnInit() {
        let {service, fileCount} = this.data;
        this.service = service;
        this.fileCount = fileCount;
    }

    closeFormHandler() {
        this.dialogRef.close();
    }
}