export const environmentShared = {
    version: '2503.1',
    updateDate: '16/03/2025',
    apiKey: '',
    environmentName: 'DEV',
    locationSearchBounds: {
        sw: {lat: -34.53691113562117, lng: 18.04938470973343},
        ne: {lat: -33.51257692467331, lng: 19.33478510035843}
    },
    apiBaseUrl: 'https://europe-west1-dev-bwrm-prism.cloudfunctions.net',
    baseUrl: 'https://dev-prism.touchfoundry.co.za',
    lastVisitedAddressKey: 'lastAddress',
    google_forms: {
        video: 'https://docs.google.com/forms/d/e/1FAIpQLSeFFKwkmyfHQShUf149I6I9XJnc-wwZ0yiHnL2ep9_5o-xgRg/viewform',
        floorplan: 'https://docs.google.com/forms/d/e/1FAIpQLSe7-h1EZoG3vCVvkxoyBnocEzAthc_xA5oOeeK6e8DIE_Fehg/formResponse',
        three_dimentional_services: 'https://docs.google.com/forms/d/14FKKMvq9UX_soS6ZwzDFeHnypNLwVnQMgnINzVhwpyg/edit?usp=sharing'
    },
    assetsServiceUrl:
        'https://dev-prism-asset.api.touchfoundry.co.za/api/v1/member-assets'
};
