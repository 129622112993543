import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {first, map, take} from 'rxjs/operators';
import {environment} from '../../environments';
import {PrismHeaders} from '../enums/headers.enum';
import {Response, Service} from '../models';
import {SnackbarService} from './snackbar.service';
import moment from 'moment';
import {BehaviorSubject} from 'rxjs';
import {IMAGE_PROCESSING} from '../enums';
import {FloorplanTicket, VideoTicket} from '../models/ticket';

@Injectable({
    providedIn: 'root'
})
export class ServicesService {
    private readonly _videoLengthAccuracyPreferences: string[];
    private readonly _videoLengthPreferences: string[];
    private readonly _videoPacePreferences: string[];
    private readonly _videoServicePremiumOptions: string[];
    private readonly _videoServiceStandardOptions: string[];
    private readonly _featuredPeopleOptions: string[];
    private readonly _includedVideoAssetsOptions: string[];
    private readonly _standardRequiredResponseOptions: string[];
    private readonly _orderPriorityOptions: string[];
    private readonly _agentsCounterOptions: string[];
    private readonly _musicOptions: string[];
    private readonly _musicGenreOptions: string[];
    private readonly _productStyleOption: string[];
    private readonly _outputFormatOption: string[];
    private readonly _propertySizeOutputFormatOption: string[];
    private _serviceTicketUpdated: BehaviorSubject<{ [serviceId: string]: string }>;

    constructor(private http: HttpClient, private snackbarService: SnackbarService) {
        this._videoLengthAccuracyPreferences = ['Approximately', 'Exactly'];
        this._videoLengthPreferences = ['15', '30', '45', '45-60', '60', '60-90', '90', '90-120', '120', '120+', 'Don\'t care'];
        this._videoPacePreferences = ['Fast', 'Slow', 'Don\'t Care'];
        this._videoServiceStandardOptions = [
            'Standard Only',
            'Standard - Basic Social',
            'Standard - Premium Social',
            'Basic Slideshow',
            'AI+ Video',
            'AI Video',
            'Property Highlights - 30 Seconds',
            'Property Highlights - 60 Seconds',
        ];
        this._videoServicePremiumOptions = [
            'Bespoke Video',
            'Premium 360 Tour - 15 Positions',
            'Premium 360 Tour - 20 Positions',
            'Premium 360 Tour - 30 Positions'
        ];
        this._featuredPeopleOptions = [
            'Agent Intro',
            'Agent Outro',
            'Model/Actors',
            'Kids'
        ];
        this._includedVideoAssetsOptions = [
            'Lifestyle/Location Footage',
            'Voice Overs',
            'Still Images',
            'Special Logos/Graphics',
            'Floor/Site Plans'
        ];
        this._standardRequiredResponseOptions = [
            'Yes',
            'No',
            'Don\'t Care'
        ];
        this._orderPriorityOptions = [
            '12 Hours (Next Morning)',
            '24 Hours (Next Evening)',
            '48 Hours',
            'Whenever'
        ];
        this._agentsCounterOptions = [
            'One Agents',
            'Two Agents',
            'Three Agents',
            'Four Agents',
            'More Than Four Agents'
        ];
        this._musicOptions = [
            'Don\'t Care',
            'Specific Genre',
            'Specific Track'
        ];
        this._musicGenreOptions = [
            'Acoustic',
            'Chill Out',
            'Country Rock',
            'Dance',
            'Electronic',
            'Folk',
            'Funky',
            'Hip Hop',
            'Indie',
            'Inspirational',
            'Jazz',
            'Pop',
            'Uplifting'
        ];
        this._outputFormatOption = [
            'FP only',
            'SP only',
            'FP + SP (Combo)',
            'FP + SP (Separated)',
            'FP + SP (Both Combo & Separated)',
            'Land Plan - BWRM',
            'Land Plan - Classic',
            'Land Plan - Custom',
        ];
        this._propertySizeOutputFormatOption = [
            'Do not include house or land size',
            'Include House size only',
            'Include Land size only',
            'Include House & Land size',
        ];
        this._productStyleOption = [
            'Vector Style',
            'WaterColour Style',
            'Classic Style',
            'Special Style',
            'Land Plan'
        ];
        this._serviceTicketUpdated = new BehaviorSubject<{ [serviceId: string]: string }>({});
    }

    saveSortedServiceAssets(serviceAssets) {
        return this.http.post(`${environment.apiBaseUrl}/StoreServiceAssetsOrder`, {serviceAssets});
    }

    toggleServiceHideFromShare(serviceId: string, hideTarget: boolean) {
        const headers = new HttpHeaders()
            .append(PrismHeaders.QueryData, JSON.stringify({serviceId, hideTarget}));

        return this.http.get(`${environment.apiBaseUrl}/ToggleServiceHideFromShare`, {headers});
    }

    getBriefs(serviceId: string) {
        const headers = new HttpHeaders()
            .append(PrismHeaders.QueryData, JSON.stringify({serviceId}));

        return this.http.get(`${environment.apiBaseUrl}/GetBriefs`, {headers})
            .pipe(
                first(),
                map((response: Response) => {
                    return response.data;
                })
            );
    }

    createTicket(service: Service, formData: any) {
        this.updateServiceTicket(service.id, 'placeholder_id');

        const headers = new HttpHeaders()
            .append(PrismHeaders.QueryData, JSON.stringify({service, formData, timestamp: moment().valueOf()}));

        this.http.get(`${environment.apiBaseUrl}/PostTicket`, {headers})
            .pipe(
                take(1),
                map((response: Response) => {
                    return response.data;
                })
            ).subscribe(
            ({id}: { id: string }) => {
                this.updateServiceTicket(service.id, id);
                if (service.SNAKE_CASE !== IMAGE_PROCESSING.SNAKE_CASE) {
                    this.snackbarService.showSnackbar('Your brief was successfully submitted.');
                }
            },
            (err) => {
                this.updateServiceTicket(service.id, null);
                console.error(err);
            }
        );
    }

    createSubTicket(service: any | Service, correction: boolean) {
        const headers = new HttpHeaders()
            .append(PrismHeaders.QueryData, JSON.stringify({service, correction}));

        this.http.get(`${environment.apiBaseUrl}/PostSubTicket`, {headers})
            .pipe(
                take(1),
            ).subscribe(
            () => null,
            (err) => console.error(err)
        );
    }

    serviceTicketUpdated$() {
        return this._serviceTicketUpdated.asObservable();
    }

    updateServiceTicket(serviceId: string, ticketId: string) {
        this._serviceTicketUpdated.getValue()[serviceId] = ticketId;
        this._serviceTicketUpdated.next(this._serviceTicketUpdated.value);
    }

    get agentsCounterOptions() {
        return this._agentsCounterOptions;
    }

    get musicOptions() {
        return this._musicOptions;
    }

    get musicGenreOptions() {
        return this._musicGenreOptions;
    }

    get videoLengthAccuracyPreferences() {
        return this._videoLengthAccuracyPreferences;
    }

    get videoLengthPreferences() {
        return this._videoLengthPreferences;
    }

    get videoPacePreferences() {
        return this._videoPacePreferences;
    }

    get videoServiceStandardOptions() {
        return this._videoServiceStandardOptions;
    }

    get videoServicePremiumOptions() {
        return this._videoServicePremiumOptions;
    }

    get featuredPeopleOptions() {
        return this._featuredPeopleOptions;
    }

    get includedVideoAssetsOptions() {
        return this._includedVideoAssetsOptions;
    }

    get standardRequiredResponseOptions() {
        return this._standardRequiredResponseOptions;
    }

    get orderPriorityOptions() {
        return this._orderPriorityOptions;
    }

    get productStyleOptions() {
        return this._productStyleOption;
    }

    get outputFormatOption() {
        return this._outputFormatOption;
    }

    get propertySizeOutputFormatOption() {
        return this._propertySizeOutputFormatOption;
    }
}