<div class="stepper-container">
    <div class="setup-account-header">
        <div class="logo">
            <img src="../../../assets/images/bwrm-new-logo.png" alt="BWRM">
        </div>

        <h1>Welcome to BWRM</h1>
    </div>

    <mat-vertical-stepper #stepper [linear]="true">
        <mat-step [completed]="stepsCompleted[0] || (memberData$|async)?.acceptedPlatformPolicies"
                  [editable]="!member?.acceptedPlatformPolicies">
            <ng-template matStepLabel>BWRM Platform Agreement</ng-template>
            <prism-terms-and-conditions [termsAndConditionsType]="'platform'"
                                        (acceptedPolicy)="completeStep(0)"></prism-terms-and-conditions>
            <button mat-button class="btn btn-red" (click)="skipStep(0)">Not Now</button>
        </mat-step>
        <mat-step [completed]="stepsCompleted[1]">
            <ng-template matStepLabel>Account Details</ng-template>
            <prism-personal-information
                    [showCompanyFields]="false"
                    (personalInformationSaved)="completeAccountDetails()"></prism-personal-information>
        </mat-step>
        <ng-container *ngIf="memberData$ | async as member">
            <mat-step [completed]="stepsCompleted[2]" *ngIf="!member?.isContractor">
                <ng-template matStepLabel>Payments</ng-template>
                <prism-payment-method [setupPage]="true"></prism-payment-method>
            </mat-step>
        </ng-container>
    </mat-vertical-stepper>
</div>

