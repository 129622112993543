import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatStepper} from '@angular/material/stepper';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {AuthenticationService} from '../../services';

@Component({
    selector: 'prism-first-time-account-setup',
    templateUrl: './first-time-account-setup.component.html',
    styleUrls: ['./first-time-account-setup.component.scss']
})
export class FirstTimeAccountSetupComponent implements OnInit, OnDestroy {
    stepsCompleted = {0: false, 1: false, 2: false};
    stepsSkipped = {0: false};
    memberData$ = this.authService.memberData$;
    member: any;
    destroySub$ = new Subject<void>();
    private isRequestInProgress = false;

    @ViewChild('stepper', {static: true}) stepper: MatStepper;

    constructor(
        private router: Router,
        private authService: AuthenticationService
    ) {
    }

    ngOnInit() {
        this.memberData$.pipe(
            take(1),
            takeUntil(this.destroySub$)
        ).subscribe(val => {
            this.member = val;
            console.log('Initial member:', this.member);

            const {acceptedPlatformPolicies} = this.member ?? {acceptedPlatformPolicies: false};

            if (acceptedPlatformPolicies && !this.stepsSkipped[0] && !this.stepsCompleted[0]) {
                this.completeStep(0).catch(err => console.error('Error completing step 0:', err));
            }

            if (
                val?.firstName &&
                val?.lastName &&
                val?.secondaryEmail &&
                val?.contactNumber &&
                !this.stepsCompleted[1]
            ) {
                this.completeStep(1).catch(err => console.error('Error completing step 1:', err));
            }
        });

        this.stepper.selectionChange.pipe(takeUntil(this.destroySub$)).subscribe(selection => {
            if (selection.selectedIndex === 0 && this.member?.acceptedPlatformPolicies && !this.stepsCompleted[0]) {
                this.completeStep(0).catch(err => console.error('Error completing step 0 on selection:', err));
            }
        });
    }

    ngOnDestroy() {
        this.destroySub$.next();
        this.destroySub$.complete();
    }

    getMember(): Promise<any> {
        return new Promise(resolve => {
            this.memberData$.pipe(
                take(1),
                takeUntil(this.destroySub$)
            ).subscribe(val => {
                this.member = val;
                resolve(this.member);
            });
        });
    }

    async completeStep(stepIndex: number): Promise<void> {
        if (this.stepsCompleted[stepIndex] || this.isRequestInProgress) return;
        console.log('Completing step:', stepIndex);
        this.stepsCompleted[stepIndex] = true;
        this.isRequestInProgress = true;

        try {
            if (stepIndex === 0 && !this.member.acceptedPlatformPolicies) {
                this.member.acceptedPlatformPolicies = true;
                this.authService.updateMemberData(this.member);
            }

            await new Promise(resolve => setTimeout(() => {
                this.stepper.next();
                resolve(void 0);
            }, 100));

            if (!this.member) {
                await this.getMember();
            }

            if (stepIndex !== 2 || this.member?.isContractor) {
                await this.checkMustNavHome();
            }
        } finally {
            this.isRequestInProgress = false;
        }
    }

    async checkMustNavHome(): Promise<void> {
        const hasBasicInfo = this.member?.lastName && this.member?.firstName && this.member?.loginEmail;
        const isSetupComplete = this.stepsSkipped[0] || this.stepsCompleted[0] || this.member?.acceptedPlatformPolicies;

        if (isSetupComplete && hasBasicInfo) {
            if (this.member?.isContractor) {
                await this.goHome();
            } else if (this.stepsCompleted[2] && this.member?.stripeId) {
                await this.goHome();
            }
        }
    }

    async skipStep(stepIndex: number): Promise<void> {
        if (this.stepsCompleted[stepIndex] || this.isRequestInProgress) return;

        this.stepsSkipped[stepIndex] = true;
        this.stepsCompleted[stepIndex] = true;
        this.isRequestInProgress = true;

        try {
            await new Promise(resolve => setTimeout(() => {
                this.stepper.next();
                resolve(void 0);
            }, 100));

            if (!this.member) {
                await this.getMember();
            }
            await this.checkMustNavHome();
        } finally {
            this.isRequestInProgress = false;
        }
    }

    async completeAccountDetails(): Promise<void> {
        if (this.isRequestInProgress) return;

        await this.completeStep(1);

        if (this.member?.isContractor) {
            await this.goHome();
        } else if (!this.member?.isContractor && !this.stepsCompleted[2]) {
            await new Promise(resolve => setTimeout(() => {
                this.stepper.selectedIndex = 2;
                resolve(void 0);
            }, 100));
        }
    }

    async goHome(): Promise<void> {
        await this.router.navigate(['home', 'jobs']).catch(err => {
            console.error('Navigation error:', err);
        });
    }
}